.results-container {
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 98;

    min-height: 85px;
    width: 70%;
    border-radius: 15px;
    background: #2b2f35;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.results-container-shared {
    position: fixed;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    min-height: 85px;
    width: 70%;
    border-radius: 15px;
    background: #2b2f35;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@keyframes fadeInUp {
    from {
        transform: translate3d(-50%,200px,0)
    }

    to {
        transform: translate3d(-50%,0,0);
    }
}
.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 0.75s;
    animation-fill-mode: both;
}

@keyframes fadeOutDown {
    from {
        transform: translate3d(-50%,0,0)
    }

    to {
        transform: translate3d(-50%,200px,0);
    }
}
.fadeOutDown {
    animation-name: fadeOutDown;
    animation-duration: 0.5s;
    animation-fill-mode: both;
}


.multi-button{
    display: flex;
    width: 200px;
    height: 40px;
}
.multi-button button {
      height: 40px;
      width: 45%;
      border: none;
      cursor: pointer;
      background: #00b28b;
      color: #333333;
}
    .multi-button button:hover {
        background-color: #00a380;
    }
    .left-button {
        border-radius: 5px 0 0 5px;
        margin-right: 1px;
    }
    .right-button {
        border-radius: 0 5px 5px 0;
        margin-left: 1px;
    }


@media screen and (max-width: 900px) {
    .results-container {
        width: 80%;
    }
    .results-container-shared {
        width: 100%;
        bottom: 0px;
        border-radius: 0px;
    }
    .results-inner-container {
        display: block;
    }
    .totalTimeResultsText {
        font-size: 20px;
    }
    .totalDistanceResultsText {
        font-size: 12px;
    }
    .directionsResultsText {
        display: none;
    }
    .mapsResultsButton {
       margin-bottom: 20px;
       width: 100%;
    }
    .mapsResultsText {
        font-size: 15px;
    }
    .shareOptions {
        margin-bottom: 15px;
    }
}