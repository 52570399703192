.stopCount-teams-container {
    height: 33px;
    border-radius: 5px;
    float: left;
  }
    .input-container-teams {
        height: 33px;
        position: relative;
        margin: auto;
        width: 90%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
        .input-container-teams.stops {
            margin-top: 10px;
        }
    .stopCount-teams-text {
        margin-top: 8.5px;
        font-size: 12px;
        text-align: left;
        color: #00b28b;
    }
    .deleteLocationTeamsButton {
        position: absolute;
        color: rgb(175, 174, 174);
        font-family: 'Varela Round', sans-serif;
        font-size: 18px;
        height: 100%;
        width: 30px;
        text-align: right;
        top: 3px;
        right: 12px;
        cursor: pointer;
    }
        .deleteLocationTeamsButton:hover {
            color: black;
        }

.stops-text-teams {
    text-align: left;
    padding-top: 20px;
}
.clear-text-message-teams {
    margin-left: 5px;
    margin-bottom: 3px;
    margin-top: 24px;
    font-size: 9px;
    color: indianred;
    background: #ffe6e0;
    font-weight: 700;
    border-radius: 3px;
    padding-top: 2px;
    padding-right: 5px;
    padding-left: 5px;
}