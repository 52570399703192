.waitlist-container {
    position: fixed;
    bottom: 20px;
    right: 23px;
    margin-left: 17px;
    transform: translateX(-50%);
    z-index: 49;

    min-height: 200px;
    max-width: 450px;
    border-radius: 10px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.appplug-container {
    position: fixed;
    bottom: 20px;
    right: 23px;
    margin-left: 17px;
    transform: translateX(-50%);
    z-index: 49;

    min-height: 200px;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@keyframes boxFadeInUp {
    from {
        opacity: 0;
        transform: translate3d(100%, 0,0)
    }

    to {
        opacity: .98;
        transform: translate3d(0,0,0);
    }
}
.boxFadeInUp {
    animation-name: boxFadeInUp;
    animation-duration: 0.75s;
    animation-fill-mode: both;
}


.btn-close-container {
    position: fixed;
    right: -10px;
    top: -10px;
}
    .btn-close {
        margin: 0;
        border: 0;
        padding: 0;
        background: #338CF5;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 150ms;
    }
    .btn-close .icon-cross {
        margin: 0;
        padding: 0;
        border: 0;
        background: none;
        position: relative;
        width: 15px;
        height: 15px;
    }
    .btn-close .icon-cross:before, .btn-close .icon-cross:after {
        content: "";
        position: absolute;
        top: 5px;
        left: 0;
        right: 0;
        height: 4px;
        background: white;
        border-radius: 6px;
    }
    .btn-close .icon-cross:before {
        transform: rotate(45deg);
    }
    .btn-close .icon-cross:after {
        transform: rotate(-45deg);
    }
    .btn-close .icon-cross span {
        display: block;
    }
    .btn-close:hover, .btn-close:focus {
        transform: rotateZ(90deg);
        background: #0064DA;
    }


.mobile-app-announcement {
    margin: auto;
    margin-top: 30px;
}
.join-waitlist-btn {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
}
.phone-svg {
    margin: auto;
    margin-bottom: 95px;
}