@keyframes fadeInUpNew {
    0% {
      opacity: 0;
      transform: translateY(50px); /* Start from below */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at original position */
    }
  }
  
  @keyframes fadeOutDownNew {
    0% {
      opacity: 1;
      transform: translateY(0); /* Start at original position */
    }
    100% {
      opacity: 0;
      transform: translateY(50px); /* Move down */
    }
  }
  
  .fade-in-up {
    animation: fadeInUpNew 0.2s ease-out forwards; /* Fade in and move up */
  }
  
  .fade-out-down {
    animation: fadeOutDownNew 0.2s ease-in forwards; /* Fade out and move down */
  }
  