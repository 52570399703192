#directions-box {
    width: 90%;
    min-height: 100px;
    margin: auto;
    background-color: #FBFBFB;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 40px;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.line-gap {
    width: 100%;
    text-align: left; 
    border-bottom: 1px solid rgb(196, 196, 196); 
    line-height: 0.1em;
}
    .line-gap span {
        padding: 10px;
        height: 50px;
        background: #FBFBFB;
        color: #7F7F7F;
    }