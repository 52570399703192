@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
.map {
    height: 100%;
    width: 100%;
}

.infowindow-text {
    font-family: 'Varela Round', sans-serif;
    margin: auto;
}



.address-popup-container {
    width: 250px;
}
.address-popup {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 7px;
    color: #4C4C4C;
}
/* Styling for the buttons container */
.buttons-container {
    display: flex;
    gap: 5px; /* Add some space between buttons */
    width: 100%; /* Ensure the container takes up full width */
}

/* Styling for the 'Move' and 'Go' buttons */
.move-button, .go-button {
    flex: 1; /* Make both buttons take up equal space */
    padding: 8px 16px;
    background-color: #00be95; /* Teal color */
    color: white;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.move-button:hover, .go-button:hover {
    background-color: #00a380; /* Darker teal on hover */
}

/* Styling for the 'Go' button */
.go-button {
    background-color: #338CF5; /* Blue color for the 'Go' button */
}

.go-button:hover {
    background-color: #0070F4; /* Darker blue on hover */
}

.separator-line {
    margin-top: 16px;
    margin-bottom: 10px;
    border: none;
    border-top: 1px solid #ccc;
}
.move-to-text {
    margin-top: 15px;
    font-size: 10px;
    font-weight: bold;
    color: #4C4C4C;
}
  
  /* Styling for each driver button */
  .driver-button {
    padding: 8px 16px;
    margin: 5px 0;
    color: white;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    transition: transform 0.3s ease;
  }
  
  .driver-button:hover {
    transform: translateY(-2px); /* Slight lift on hover */
  }
  



#big-loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
}
#big-loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #00a380;
    -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
    animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 
#big-loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #00b28b;
    -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 
#big-loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #00be95;
    -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
      animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}
 
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(0deg);  /* IE 9 */
        transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
    }
    100% {
        -webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
        -ms-transform: rotate(360deg);  /* IE 9 */
        transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
    }
}
  