/* @import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap'); */

.logo-title {
    font-family: 'Varela Round', sans-serif;
    font-size: 25px;
    font-weight: 900;
    color: #00b28b;
}

#menu-selection {
    width: 40%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 15px;
}

.nightsvg {
  z-index: -1;
}
.bubble {
  z-index: -1;
}
.page-svgs {
  z-index: -1;
}
.flip {
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.landingSvg {
  position: absolute;
  top: 60px;
  left: 55%;
  transform: rotate(-25deg);
}

@media screen and (max-width: 1000px) {
    .nightsvg {
      display: none;
    }
    .bubble {
      display: none;
    }
  }

@media screen and (max-width: 1200px) {
  .nightsvg {
    left: 65%;
  }
}

@media screen and (max-width: 1024px) {
  .giftsvg {
    display: none;
  }
}

.header-bg-color {
  background-color: none; /* Replace with your new color */
}
.header-bg-new-color {
  background-color: rgba(63, 61, 86, 0.9);
}

.header-text-color {
  color: #666666;
}
.header-text-color li:hover {
  color: #191919;
}
.header-text-new-color {
  color: white;
}
.header-text-new-color li:hover {
  color: rgb(189, 187, 187);
}

.header-signin-color {
  color: #666666;
}
.header-signin-color:hover {
  color: #191919;
}
.header-signin-new-color {
  color: white;
}
.header-signin-new-color:hover {
  color: rgb(189, 187, 187);
}

.corner-button-color {
  color: #EAEAEA;
  background-color: #3f3d56;
}
.corner-button-color:hover {
  background-color: #333333;
}
.corner-button-new-color {
  color: #3f3d56;
  background-color: #EAEAEA;
}
.corner-button-new-color:hover {
  background-color: rgb(189, 187, 187);
}

.menu-button-color {
  background-color: #3f3d56;
}
.menu-button-color:hover {
  background-color: #333333;
}
.menu-button-new-color {
  background-color: white;
}



@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 5));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 5));
  }
}
.slider {
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider::before, .slider::after {
  background-image: linear-gradient(to left, transparent, rgba(255, 255, 255, 0));
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 40s linear infinite;
          animation: scroll 40s linear infinite;
  display: flex;
  width: calc(250px * 10);
}
.slider .slide {
  height: 100px;
  width: 200px;
}

@media screen and (max-width: 768px) {

  @-webkit-keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-170px * 5));
    }
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-170px * 5));
    }
  }

  .slider::before, .slider::after {
    width: 170px;
  }

  .slider .slide {
    width: 170px;
  }

  .slider .slide-track {
    -webkit-animation: scroll 20s linear infinite;
            animation: scroll 20s linear infinite;
  }

}

