.toggle {
    --width: 60px;
    --height: calc(var(--width) / 2);
    --border-radius: calc(var(--height) / 2);
  
    display: inline-block;
    cursor: pointer;
  }
  
  .toggle__input {
    display: none;
  }
  
  .toggle__fill {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: var(--border-radius);
    background: #dddddd;
    transition: background 0.2s;
  }
  
  .toggle__input:checked ~ .toggle__fill {
    background: #338cf5;
  }
  
  .toggle__fill::after {
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
    height: 32px;
    width: 32px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    border-width: 3px;
    border-color: #338cf5;
    transition: transform 0.2s;
  }
  
  .toggle__input:checked ~ .toggle__fill::after {
    transform: translateX(var(--height));
  }
  


.annual-deal-message {
  margin-left: 5px;
  margin-top: 2px;
  font-size: 12px;
  color: #00b28b;
  background: #e0fff8;
  font-weight: 700;
  border-radius: 3px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-right: 5px;
  padding-left: 5px;
}
.annual-deal-message-off {
  margin-left: 5px;
  margin-top: 2px;
  font-size: 12px;
  color: #b9b9b9;
  background: #e6e6e6;
  font-weight: 700;
  border-radius: 3px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-right: 5px;
  padding-left: 5px;
}

.dashed-line {
  border-top: 2px dashed #DFDFDF;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 15px;
}

.newandimproved {
  background: #fcdede;
  color: #f87070;
  font-weight: 600;
  font-size: 10px;
  border-radius: 4px;
  height: 20px;
  margin-top: 5px;
  padding-inline: 7px;
  padding-top: 3px;
  margin-left: 5px;
}
