.StripePaymentInput {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #0e162e;
    background-color: white;
    border: 1px solid;
    border-color: #EAEAEA;
    border-radius: 8px;
  
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  
    color: #32325d;
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
  }
  .StripePaymentInput:focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  .StripePaymentInput:invalid {
    border-color: #fa755a;
  }
  .StripePaymentInput::placeholder {
    color: #aab7c4;
  }
  
  /* #email-payment::placeholder {
    color: #b4b4c5;
  } */
  
  /* @media screen and (max-width: 1024px) {
    .worldsvg {
      display: none;
    }
    .paymentcard {
      margin-left: 30px;
      margin-right: 30px;
    }
    .paymentpageHeader {
      position: relative;
      margin: 30px;
      right: 0;
      top: 0;
    }
    .buffer-200 {
      height: 0;
    }
  } */

  .checkoutinfo {
    width: 460px;
  }
  @media screen and (max-width: 1024px) {
    .checkoutinfo {
      width: 100%;
    }
    .paymentcard {
      width: 100%;
    }
  }


  .StripeOtherInput {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #0e162e;
    background-color: white;
  
    color: '#32325d';
    font-size: 14px;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
  }
  .StripeOtherInput:focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  .StripeOtherInput::placeholder {
    color: #6e7379;
  }

.cardholder-info {
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  border-radius: 8px;
}
#email-firstname {
  border-radius: 8px 0px 0px 0px;
  border-top: 1px solid #EAEAEA;
  border-left: 1px solid #EAEAEA;
  border-right: 1px solid #EAEAEA;
  border-color: #EAEAEA;
}
#email-lastname {
  border-radius: 0px 8px 0px 0px;
  border-color: #EAEAEA;
  border-top: 1px solid #EAEAEA;
  border-right: 1px solid #EAEAEA;
}
#email-payment {
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #EAEAEA;
  border-color: #EAEAEA;
}
#promo-code {
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #EAEAEA;
  border-color: #EAEAEA;
}
#promo-code-submit {
  border-radius: 0px 8px 8px 0px;
  padding-inline: 15px;
}

#address-1 {
  border-radius: 8px 8px 0px 0px;
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #EAEAEA;
}
#address-2 {
  border: 1px solid;
  border-color: #EAEAEA;
}
#address-city {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: #EAEAEA;
}
#address-country {
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #EAEAEA;
}
#address-state {
  border-radius: 0px 0px 0px 8px;
  border-bottom: 1px solid;
  border-left: 1px solid;
  border-color: #EAEAEA;
}
#address-zip {
  border-radius: 0px 0px 8px 0px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-color: #EAEAEA;
}

.plusvisual {
  border-radius: 14px 14px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px -3px 8px;
}

.innershadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}


.checkout-subtitles {
  font-size: 14px;
  font-weight: 500;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  margin-bottom: 3px;
}

.dot {
  height: 11px;
  width: 11px;
  margin-right: 5px; /* Adjust space between dots */
  background-color: #1a806a; /* Inactive color */
  border-radius: 50%;
  display: inline-block;
}

.dot.active {
  background-color: #fff; /* Active color */
}


@keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(20%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeOutToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-15%);
  }
}

.fadeInRight {
  animation: fadeInFromRight 1s ease forwards;
}
.fadeOutLeft {
  animation: fadeOutToLeft 0.7s ease forwards;
}



