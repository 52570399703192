.splitModalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(177, 177, 177, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .splitModalContainer {
    max-width: 600px;
    margin-inline: 15px;
    height: 550px;
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-inline: 20px;
    padding-top: 3px;
    overflow: scroll;
  }
  .splitModalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .splitModalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .splitCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .splitCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: gray;
    font-family: 'Varela Round', sans-serif;
    font-weight: 100;
  }