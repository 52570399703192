.driversModalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(177, 177, 177, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .driversModalContainer {
    width: 400px;
    margin-inline: 15px;
    height: 550px;
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    position: relative;
  }
  .shareRoutesModalContainer {
    width: 500px;
    margin-inline: 15px;
    height: 550px;
    border-radius: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    position: relative;
  }

  .driversCloseBtn {
    position: absolute;
    top: 13px;
    right: 8px;
  }

  .driversRouteBreakdownCloseBtn {
    position: absolute;
    top: 67px;
    right: 10px;
  }