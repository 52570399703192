.grid-elements {
    /* border-color: #EAEAEA;
    background-color: white */
    border-color: #a7a2de;
    background-color: rgba(167, 162, 222, 0.2)
}

.grid1-parent {
    height: auto;
}

.grid1-container {
    position: relative;
    height: auto; /* Set to auto for responsive height adjustment */
}

.grid1-img {
    position: relative; /* Change to relative for mobile */
    width: 100%;        /* Full width on mobile */
    height: auto;       /* Adjust height automatically */
    top: 0;             /* Reset top position */
    left: 0;            /* Reset left position */
    transform: none;    /* Remove transform on mobile */
}

.text-container {
    order: 2;           /* Ensure text container comes after image on mobile */
}

.results-container-1 {
    position: absolute;
    top: 50px;
    left: -20px;
    z-index: 1;

    min-height: 85px;
    width: 700px;
    border-radius: 15px;
    background: #2b2f35;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    -webkit-animation: floaty 6s ease-in-out infinite;
    animation: floaty 6s ease-in-out infinite;
}

@-webkit-keyframes floaty {
    0% {
      box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
    50% {
      box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(-10px);
    }
    100% {
      box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
  }
  
  @keyframes floaty {
    0% {
      box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
    50% {
        box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(-10px);
    }
    100% {
      box-shadow: 0 2px 5px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
  }

@media screen and (max-width: 768px) {
    .grid1-parent {
        flex-direction: column;
    }

    .grid1-container {
        width: 95%;
        border-radius: 0px 0px 12px 0px;
        margin-right: auto;
        margin-left: 0;
    }

    .results-container-1 {
        display: none;
    }

    .shareOptions.visual {
        display: none;
    }
}



.grid2-container {
    position: relative;
    height: auto; /* Set to auto for responsive height adjustment */
}

.grid3-parent {
    height: 480px; 
    width: 100%;
}

.grid4-parent {
    height: 480px;
    width: 100%;
}

.dot-window {
    height: 10px;
    width: 10px;
    margin-right: 5px;
    border-radius: 50%;
    display: inline-block;
  }
.dot-window.red {
    background-color: #FF6057;
}
.dot-window.yellow {
    background-color: #FFBC2E;
}
.dot-window.green {
    background-color: #27C840;
}


.grid4-parent .grid4-img {
    transition: transform 0.3s ease-in-out;
    transform: translateY(0);
}
.grid4-parent:hover .grid4-img {
    transform: translateY(-15px);
}

.grid3-parent {
    overflow: hidden;
    transition: width 0.3s ease-in-out;
}
.route-chart {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
    width: calc(100% + 30px);
}
.grid3-parent:hover .route-chart {
    transform: translateX(-15px);
}

@media screen and (max-width: 768px) {
    .grid3-parent {
        min-height: 500px;
    }
    .dot-window {
        height: 8px;
        width: 8px;
    }
}