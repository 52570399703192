
.main-box {
    display: flex;
    width: 90%;
    height: 625px;
    margin: auto;
    background-color: white;
    margin-top: 10px;
    position: relative;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.map-container {
  width: 65%;
}

.address-form {
    flex: 1;
    width: 35%;
    overflow: scroll;
    background: white;
}
    .form-message {
        text-align: left;
        margin-left: 10%;
    }
    .or-text {
        width: 50%;
        text-align: center; 
        border-bottom: 1px solid rgb(196, 196, 196); 
        line-height: 0.1em;
        margin: auto;
        font-family: 'Varela Round', sans-serif;
    }
        .or-text span {
            padding: 10px;
            height: 50px;
            background: white;
            color: rgb(196, 196, 196);
        }
    .padding1 { 
        height: 20px;
    }
    .padding2 { 
        height: 10px;
    }


.location-container {
    height: 40px;
    position: relative;
    margin: auto;
    width: 80%;
    margin-top: 20px;
    margin-bottom: 25px;
}
/* INPUT CSS */

.input-container {
    height: 40px;
    position: relative;
    margin: auto;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .input-container.stops {
    margin-top: 10px;
  }
.input-container-location {
    height: 40px;
    position: relative;
    width: 85%;
    /* margin-top: 10px; */
    margin-bottom: 10px;
    float: left;
}
  .currLocation-button {
    background-color: rgb(240, 240, 240);
    height: 40px;
    width: 12%;
    border-radius: 5px;
    /* margin-top: 10px; */
    margin-bottom: 10px;
    float: right;
    cursor: pointer;
  }
    .currLocation-text {
        font-size: 25px;
        vertical-align: middle;
        color: #00b28b;
        cursor: pointer;
    }
  .stopCount-container {
    background-color: rgb(240, 240, 240);
    height: 40px;
    width: 5%;
    border-radius: 5px;
    margin-bottom: 10px;
    float: left;
  }
    .stopCount-text {
        margin-top: 11px;
        font-size: 15px;
        text-align: left;
        color: #00b28b;
    }
  
  .input {
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
    border: 0;
    box-sizing: border-box;
    color: gray;
    font-size: 15px;
    height: 100%;
    outline: 0;
    padding: 11px 20px 11px;
    width: 100%;
  }
  .input-stops {
    background-color: white;
    border-radius: 5px;
    border: 0;
    box-sizing: border-box;
    color: gray;
    font-size: 15px;
    height: 100%;
    outline: 0;
    padding: 11px 20px 11px;
    padding-right: 50px;
    width: 93%;
    float: right;
  }
    .input-stops:hover {
        background-color: rgb(250, 250, 250);
    }
    .input-stops:focus {
        background-color: rgb(250, 250, 250);
    }
    .input-stops::placeholder {
        color: rgb(209, 209, 209);
    }
    .deleteLocationButton {
        position: absolute;
        color: gray;
        font-family: 'Varela Round', sans-serif;
        font-size: 22px;
        height: 100%;
        width: 30px;
        text-align: right;
        top: 4px;
        right: 17px;
        cursor: pointer;
    }
        .deleteLocationButton:hover {
            color: black;
        }
  .input:hover {
    background-color: rgb(230, 228, 228);
  }
  .input:focus {
    background-color: rgb(230, 228, 228);
  }
  .currLocation-button:hover {
    background-color: rgb(230, 228, 228);
  }
  
  .cut.mapsUrl {
    background-color: white;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 123px;
  }
  .cut.startLocation {
    background-color: white;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 99px;
  }
  .cut.endLocation {
    background-color: white;
    border-radius: 10px;
    height: 20px;
    left: 20px;
    position: absolute;
    top: -20px;
    transform: translateY(0);
    transition: transform 200ms;
    width: 93px;
  }
  
  .cut-short {
    width: 50px;
  }
  
  .input:focus ~ .cut,
  .input:not(:placeholder-shown) ~ .cut {
    transform: translateY(8px);
  }
  
  .placeholder {
    color: gray;
    font-family: 'Varela Round', sans-serif;
    left: 20px;
    line-height: 14px;
    pointer-events: none;
    position: absolute;
    transform-origin: 0 50%;
    transition: transform 200ms, color 200ms;
    top: 13px;
  }
  
  .input:focus ~ .placeholder,
  .input:not(:placeholder-shown) ~ .placeholder {
    transform: translateY(-21px) translateX(10px) scale(0.75);
  }
  
  .input:not(:placeholder-shown) ~ .placeholder {
    color: gray;
  }
  
  .input:focus ~ .placeholder {
    color: gray;
  }
/* END OF INPUT CSS   */

.stops-container {
    min-height: 500px;
    background: rgb(240, 240, 240);
    margin-top: 30px;
    width: 100%;
}
    .stops-text {
        text-align: left;
        margin-left: 10%;
        padding-top: 20px;
    }
    .stops-text-message {
      margin-left: 5px;
      margin-bottom: 2px;
      margin-top: 23px;
      font-size: 10px;
      font-weight: 700;
      border-radius: 3px;
      padding-top: 3px;
      padding-right: 5px;
      padding-left: 5px;
    }
    .clear-text-message {
      margin-left: 5px;
      margin-bottom: 2px;
      margin-top: 23px;
      font-size: 10px;
      color: indianred;
      background: #ffe6e0;
      font-weight: 700;
      border-radius: 3px;
      padding-top: 3px;
      padding-right: 5px;
      padding-left: 5px;
    }



.optimize-button {
    margin: auto;
    height: 50px;
    width: 20%;
    background: #00b28b;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;

    position: absolute;
    left: 7.5%;
    bottom: 30px;
}
    .optimize-button p{
        color: white;
    }
    .optimize-button:hover {
        background: #00a380;
        cursor: pointer;
    }
    #optimize-text {
      text-align: center;
      width: 100%;
      font-size: 17px;
      color: white;
      margin-top: 12px;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
    }
@media screen and (max-width: 900px) {
  .main-box {
    flex-direction: column; /* Stack the map on top */
    height: auto; /* Adjust height as necessary */
  }

  .map-container {
    width: 100%; /* Make map take full width */
    order: -1; /* Make map appear first */
    height: 350px; /* Set a fixed height or make it responsive */
  }

  .address-form {
    width: 100%; /* Make address form take full width */
    /* Reset any margins or padding if necessary */
  }
  .optimize-button {
    width: 80%;
    left: 10%; /* 10% from both sides will center it */
    bottom: 30px; 
  }
}

.buffer-200 {
    height: 200px;
}
.buffer-100 {
  height: 100px;
}

/* AUTCOMPLETE */
.autocomplete-dropdown-container {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 350px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 99;
    top: 40px;
}
.suggestion-item, .suggestion-item--active{
    width: 350px;
    text-align: left;
    margin: auto;
    /* font-family: 'Varela Round', sans-serif; */
    font-size: 13px;

    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
    padding-left: 10px;

    display: block;
}

/* loading bar */
.loading-container {
  display: flex;
  width: 100%;
  min-height: 45%;
  align-items: center;
  vertical-align: middle;
}
.loading-container.currLocation {
  margin-top: 12px;
}
.loading-container.optimize {
  margin-top: 15px;
}
.loading-container.submit {
  margin-top: 14px;
}
.loading-container.submitList {
  margin-top: 11px;
}
.loading-container.starttrial {
  padding-top: 12px;
  padding-bottom: 12px;
}

.loading-container-new {
  margin-left: 5px;
  margin-top: 50%;
}
  
.loader {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  min-height: 100%;
  display: table-cell;
  vertical-align: middle;
  opacity: 0.4;
} 

.loading-circle {
  margin: 0 auto;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid #BEB69D;
  position: relative;
  animation: spin 0.75s infinite linear;
}
.loading-circle::before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: -4px;
    top: -4px;
    border-right: 4px solid #BEB69D;
    border-top: 4px solid #BEB69D;
    border-left: 4px solid #FFF;
    border-bottom: 4px solid #BEB69D;
}

.bigger-loading-circle {
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid #338CF5;
  position: relative;
  animation: spin 0.75s infinite linear;
}
.bigger-loading-circle::before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    position: absolute;
    left: -4px;
    top: -4px;
    border-right: 4px solid #338CF5;
    border-top: 4px solid #338CF5;
    border-left: 4px solid #FFF;
    border-bottom: 4px solid #338CF5;
}

@keyframes spin {
  100% {transform: rotate(360deg)}
}
/* end */

.currLocation-icon {
    height: 30px;
    width: 30px;
    margin: auto;
    margin-top: 5px;
}