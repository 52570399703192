.selectModalBackground {
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(200, 200, 200, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

    font-family: 'Inter', sans-serif;
  }
  
    .selectModalContainer {
        width: 30%;
        border-radius: 12px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        min-height: 300px;
        max-height: 400px;
        overflow: scroll;
    }

    .radioOption {
        display: block; /* This ensures each radio option is on a new line */
        margin-bottom: 10px; /* This adds some space between the options */
      }
      
      .columnSelectionForm {
        padding: 10px;
      }
      
      .submitContainer {
        text-align: center; /* Center-align the submit button */
        margin-top: 20px; /* Add some space above the submit button */
      }
      
      .submitSelectionButton {
        padding: 10px 20px;
        background-color: #338CF5;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
      }
      
      .submitSelectionButton:hover {
        background-color: #1A7CC0;
      }
      