.mode-buttons-container {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.mode-button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  margin-left: 20px;
  margin-right: 20px;
}
.mode-button:hover {
  
  }
  #mode-driving svg {
    margin-left: 7px;
    margin-top: 6px;
  }
  #mode-bicycling svg {
    margin-left: 8px;
    margin-top: 7px;
  }
  #mode-walking svg {
    margin-left: 10px;
    margin-top:8px;
  }