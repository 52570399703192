.modalBackgroundEx {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .modalContainerEx {
    width: 90%;
    height: 90vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    overflow: scroll;
  }
  
  .modalContainerEx .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtnEx {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtnEx button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    margin-right: 12px;
    cursor: pointer;
    color: gray;
    font-family: 'Varela Round', sans-serif;
  }
  
  .modalContainerEx .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .modalContainerEx .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainerEx .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    color: white;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
  }