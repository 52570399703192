.sortable-item .handle {
  visibility: hidden;
  color: #4b5563;
}

.sortable-item:hover .handle {
  visibility: visible;
}

.sortable-item .handle:hover {
  color: #000;
}

.sortable-item.is-dragging .handle {
  visibility: visible;
  color: #000;
}

.sortable-item {
  outline: none;
}
