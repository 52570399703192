.listModalBackground {
    width: 100vw;
    min-height: 100vh;
    background-color: rgba(200, 200, 200, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;

    font-family: 'Inter', sans-serif;
  }
  
    .listModalContainer {
        width: 50%;
        border-radius: 12px;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        height: 524px;
    }
        .listModalContainerInner {
            height: 524px;
            padding: 40px;
            overflow: scroll;
            scroll-behavior: smooth;
        }

    .submit-button {
        height: 35px;
        width: 30%;
        border-radius: 5px;

        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
    .submit-text {
        text-align: center;
        width: 100%;
        font-size: 14px;
        color: white;
        margin-top: 11px;
        font-family: 'Inter', sans-serif;
        font-weight: 600;

        margin-top: 8px;
    }

    .import-button {
        height: 50px;
        width: 40%;
        border-radius: 5px; 
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding: 4px;
        
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;
    }
    .import-text {
        text-align: center;
        width: 100%;
        font-size: 17px;
        color: white;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        margin-top: 10px;
    }