/* Customize the dropdown calender */
.react-datepicker {
    font-family: 'Inter', sans-serif;
    border: 0px solid #00b28b;
  }
  
  .react-datepicker__day--selected {
    background-color: #00B28B;
    color: white;
  }
  
  .react-datepicker__header {
    background-color: #00B28B;
    color: white;
    padding: 8px;
    border-bottom: 2px solid #00B28B;
    border-radius: 8px 8px 0 0;
  }
  
  .react-datepicker__current-month {
    font-weight: 600;
    color: white;
  }
  
  .react-datepicker__day:hover {
    background-color: #f0f0f0;
  }

  .react-datepicker__day-name {
    color: white;
  }
  



/* NOT WORKING RN */  
/* Container styling for the calendar from rainbow */
.calendar-main {
    max-width: 400px; /* Corrected with units */
}

/* Override the calendar icon color */
.rainbow-datepicker_icon path {
    fill: #00ff88 !important; /* Use !important to ensure it overrides */
}

/* Override the outline color of the datepicker input field */
.rainbow-datepicker_input.rainbow-input {
    border-color: #0000ff !important; /* Use !important for strong specificity */
}

/* Customize the calendar popup border */
.rainbow-datepicker-calendar {
    border-color: #00b28b !important; /* Custom border color */
    box-shadow: 0px 0px 10px #00b28b; /* Add shadow for emphasis */
}

