.reading-time {
    margin-left: 5px;
    font-size: 12px;
    color: #00b28b;
    background: #e0fff8;
    font-weight: 700;
    border-radius: 3px;
    padding-top: 1px;
    padding-bottom: 1px;
    padding-right: 7px;
    padding-left: 7px;
  }
.reading-time-big {
  margin-left: 10px;
  font-size: 15px;
  color: #00b28b;
  background: #e0fff8;
  font-weight: 600;
  border-radius: 3px;
  padding-top: 2px;
  padding-bottom: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.blog-card:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}